import React from "react"

import Layout from "components/Layout"
import Seo from "components/Seo"
import Breadcrumbs from "components/Breadcrumbs"
import PageHeader from "components/PageHeader"

import { Content } from "page_components/config"

import ConfigProvider from "context/config"
import { useProducts } from "context/products"

const ConfigContent = ({ location }) => {
  const { products } = useProducts()

  const formatted_products = products?.filter(item =>
    item?.productCategories?.nodes?.some(item => item?.slug === "tapety")
  )

  return (
    <ConfigProvider location={location} products={formatted_products}>
      <Content />
    </ConfigProvider>
  )
}

const Config = ({ location }) => {
  return (
    <Layout location={location}>
      <Breadcrumbs title="Konfigurator" />
      <PageHeader title="Dopasuj tapetę do swoich potrzeb" />
      <ConfigContent location={location} />
    </Layout>
  )
}

export const Head = () => (
  <Seo
    title="Spersonalizuj Zamówienie - Odmień Swoje Wnętrze"
    description="Stworzone przez nas ekskluzywne tapety dekoracyjne zachwycają swoim designem. Spersonalizuj swoje zamówienie na wallcraft.pl i ciesz się nowoczesnym wnętrzem."
  />
)

export default Config
